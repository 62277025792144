import React from "react";
import Slider from "react-slick";
// import { GatsbyImage, getImage } from "gatsby-plugin-image";

import AriadneDigitalLogo from "../images/loghi-gruppo/ariadne-logo.png";
import AriadneDigitalBG from "../images/ariadne-digital.jpg";
import AriadneELearningLogo from "../images/loghi-gruppo/ariadne-elearning-logo.png";
import AriadneELearningBG from "../images/ariadne-elearning.png";
import IntranetManagementLogo from "../images/loghi-gruppo/intranet-managemet-logo.png";
import IntranetManagementBG from "../images/intranet-management.jpg";
import WeyotechLogo from "../images/loghi-gruppo/weyotech-logo.png";
import WeyotechBG from "../images/weyotech.png";
import Intranet8020Logo from "../images/loghi-gruppo/intranet-8020-logo.png";
import Intranet8020BG from "../images/intranet-8020.png";
import ColibriLogo from "../images/loghi-gruppo/colibri-logo.png";
import ColibriBG from "../images/colibri.png";

import Arrow from "../svg/arrow.svg";
import SquareProgetti from "../svg/square-progetti.svg";

const settings = {
  arrows: false,
  dots: true,
  infinite: false,
  swipe: true,
  slidesToShow: 2,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const aziende = [
  {
    title: "Ariadne Digital",
    desc: "Digital Consulting Coordination & Management",
    logo: AriadneDigitalLogo,
    bg: AriadneDigitalBG,
    link: "https://www.ariadnedigital.it/",
  },
  {
    title: "Ariadne E-Learning",
    desc: "Digital Learning Experience",
    logo: AriadneELearningLogo,
    bg: AriadneELearningBG,
    link: "https://www.ariadnelearning.it/",
  },
  {
    title: "Intranet 8020",
    desc: "Intranet Packaged Solution",
    logo: Intranet8020Logo,
    bg: Intranet8020BG,
    link: "https://www.intranet8020.it/",
  },
  {
    title: "Colibrì",
    desc: "eCommerce Shopify",
    logo: ColibriLogo,
    bg: ColibriBG,
    link: "https://wearecolibri.com/",
  },
  {
    title: "Weyotech",
    desc: "Mobile Engineering",
    logo: WeyotechLogo,
    bg: WeyotechBG,
    link: "https://www.weyotech.com/",
  },
  {
    title: "Intranet Management",
    desc: "Strategy & Employees Engagement",
    logo: IntranetManagementLogo,
    bg: IntranetManagementBG,
    link: "https://www.intranetmanagement.it/",
  },
];

const Aziende = () => {
  return (
    <>
      <section className="section section--primary section--aziende">
        <SquareProgetti className="square" />
        <div className="container">
          <div className="row">
            <div className="col-12 text-xl-end">
              <div className="section__title section__title--aziende-gruppo">
                Aziende del <span>gruppo</span>
              </div>
            </div>
          </div>
        </div>
        <div className="wrapper-slider-aziende">
          <div className="container-slider-aziende">
            <Slider {...settings} className="slider-aziende">
              {aziende.map((item, i) => (
                <div key={i}>
                  <div className="box-azienda">
                    <div className="box-azienda__bg d-flex justify-content-center align-items-center">
                      {item.bg && (
                        <img src={item.bg} alt={item.title} className="box-azienda__bg-img" />
                      )}
                      {item.logo && (
                        <div className="box-azienda__logo d-flex justify-content-center align-items-center">
                          <img src={item.logo} alt={item.title} className="box-azienda__img" />
                        </div>
                      )}
                    </div>
                    <div className="box-azienda__title">
                      <a
                        href={item.link}
                        title="Vai al sito"
                        className="box-azienda__link"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {item.title}
                      </a>
                    </div>
                    {item.desc && (
                      <div className="box-azienda__desc">
                        <p>{item.desc}</p>
                      </div>
                    )}
                    {item.link && (
                      <div>
                        <a
                          href={item.link}
                          title="Vai al sito"
                          className="box-azienda__link"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Arrow className="box-azienda__arrow" />
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </section>
    </>
  );
};

export default Aziende;
