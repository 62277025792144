import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";

import SliderCompetence from "../components/slider-competence";
import Aziende from "../components/aziende";

import AriadneGroup from "../svg/ariadne-group-logo.svg";
import SquareDx from "../svg/square-chisiamo.svg";
import SquareSx from "../svg/square-chisiamo-2.svg";

const hero = [
  {
    title: "Digital & Cloud Engineering",
    description: "Sviluppare tecnologie digitali al servizio del business",
  },
];
const breadcrumb = [{ title: "Home", link: "/" }, { title: "About" }];

const ChiSiamo = ({ location }) => {
  return (
    <Layout location={location} hero={hero} breadcrumb={breadcrumb}>
      <Seo
        title={`About`}
        description={`Ariadne è la società del gruppo Ariadne Digital che, da oltre 25 anni, è specializzata nello sviluppo di piattaforme digitali evolute`}
      />

      <div className="section section--chisiamo">
        <SquareDx className="square-dx d-none d-lg-block" />
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-9 col-xl-7">
              <p>
                Ariadne è una realtà specializzata nella realizzazione di piattaforme digitali
                evolute attraverso l’impiego di tecnologie e metodologie in continuo sviluppo.
              </p>
              <p>
                A partire dagli anni Novanta, la società ha realizzato oltre 350 portali ed è in
                grado di offrire una consolidata expertise nel Digital Engineering e nello sviluppo
                di sistemi di classe enterprise con tecnologia Liferay, Cloud Applications, AWS
                Cloud Native Apps.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="section section--chisiamo">
        <SquareSx className="square-sx d-none d-lg-block" />
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-9 offset-lg-3 col-xl-7 offset-xl-5">
              <p>
                Ariadne è partner platinum Liferay e Selected Consulting Partner di AWS. È
                organizzata in tre Competence Center, dove ciascun centro è dedicato alle tecnologie
                che rappresentano il core business dell’azienda:
                <br />
                LIFERAY Competence Center;
                <br />
                AWS Competence Center;
                <br />
                Cloud Applications Competence Center.
              </p>
              <p>
                Attraverso il costante aggiornamento e un’esperienza decennale, il team di Ariadne è
                in grado di supportare i progetti dei clienti con portali, App e piattaforme di
                eccellenza.
              </p>
            </div>
          </div>
        </div>
      </div>

      <SliderCompetence />

      <section className="section ariadne-group">
        <div className="container">
          <div className="row">
            <div className="col-md-7 offset-md-1">
              <h2 className="section__title section__title--small">Il Gruppo</h2>
              <p>
                Ariadne è una società di Ariadne Group, organizzazione di aziende costituita per
                fornire un orientamento concreto nella realizzazione di progetti digitali di
                eccellenza.
              </p>
              <p>
                Il metodo di Ariadne Group garantisce risultati senza compromessi, grazie a una
                lucida comprensione delle esigenze e una gestione completa del processo, grazie al
                coinvolgimento di strutture verticali esperte, ognuna nel proprio ambito di
                specializzazione.
              </p>
            </div>
            <div className="col-md-4">
              <AriadneGroup className="ariadne-group-logo ariadne-group-logo--color" />
            </div>
          </div>
        </div>
      </section>

      <Aziende />
    </Layout>
  );
};

export default ChiSiamo;
